<template>
    <div class="main">
        <van-nav-bar
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
        title="我的邀请二维码" />
        <div class="main1">
            <div class="logo"></div>
            <div class="title1">一对一<span style="color: #FF2F7BFF">私密</span>视频聊天</div>
            <div class="title2">超甜蜜，海量单身男女恋爱约会</div>

            <div class="qrWarp">
                <vue-qr class="qrCode" :logoSrc="imageUrl" :text="link.url" :size="157" :margin="0"></vue-qr>
            </div>

            <div class="title3">【截屏保存二维码】</div>
        </div>
    </div>
</template>

<script>
import vueQr from 'vue-qr'

export default {
    data() {
        return {
            userId: '',
            link: {
                url: '',
            },
            imageUrl: require("../../assets/inviteEarnGold/qrCode_logo.png"),
        }
    },
    components: {
        vueQr
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
    },
    created() {
        this.userId = this.$route.query['userId']
        this.link.url = 'http://h5.dwud.cn/inviteEarnGold/invite?userId='+this.userId
    },
    // mounted() {
    //     this.userId = this.$route.query['userId']
    //     this.$set(this.link, 'url', 'http://h5.dwud.cn/inviteEarnGold/invite?userId='+this.userId)
    // }
}
</script>

<style scoped>
.main {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    width: 375px;
    height: 812px;

    background: url('../../assets/inviteEarnGold/pic_bg.png');
    background-repeat: no-repeat ;
    background-position: center;
    background-size: 100% ;
}

.main1 {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    width: 375px;
    height: 812px;
}

.logo {
    width: 63px;
    height: 102px;
    margin-top: 105px;
    /* margin-left: 153px; */

    background: url('../../assets/inviteEarnGold/logo.png');
    background-repeat: no-repeat ;
    background-position: center;
    background-size: 100% ;
}

.title1 {
    margin-top: 54px;
    /* width: 214px; */
    height: 22px;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}

.title2 {
    margin-top: 14px;
    /* width: 166px; */
    height: 19px;
    font-size: 19px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}

.qrWarp {
    margin-top: 33px;
    width: 165px;
    height: 165px;
    background: #FFFFFF;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.qrCode {
    width: 157px;
    height: 157px;
}

.title3 {
    margin-top: 13px;
    height: 14px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
}
</style>